import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import Breadcrumb from "../components/breadcrumb"
import SEO from "../components/seo"
import Img from "gatsby-image"
import SliderGeneric from "../components/slider-generic"

export default function Theme({ data }) {
    const theme = data.activitydata
    const name = theme.frontmatter.title
    const seotitle = `${name} Staycations`
    let crumbdata = [ ['Staycation Themes','themes'],`${name}`]
    let slidertitle = `Destinations for ${name}`
  return (
    <Layout>
        <SEO title={seotitle} description="Never been beyond your house and the airport? Don't worry we've got you sorted." />
        <Breadcrumb crumbs={ crumbdata } />
        <Img
          fluid={theme.keyimage.childImageSharp.fluid}
        />
        <h1>{name} in the UK</h1>
      <div dangerouslySetInnerHTML={{ __html: theme.html }}></div>
      <SliderGeneric data= {data.locationdata} title={slidertitle} />
    </Layout>
  )
}


export const query = graphql`
  query($slug: String!,$title: String!) {
    activitydata: markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      frontmatter {
        title
      }
      keyimage {
        childImageSharp {
          fluid(maxWidth: 925, maxHeight: 500) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
    locationdata: allMarkdownRemark(filter: {frontmatter: {type: {eq: "destination"}, themes: { eq: $title } }}) {
      totalCount
      edges{
          node{
              frontmatter {
                  title
                }
                fields{
                    slug
                }
                keyimage {
                  childImageSharp {
                    fluid(maxWidth: 300, maxHeight: 300) {
                      ...GatsbyImageSharpFluid
                    }
                  }
                }
          }
      }
  
    }
  }
`